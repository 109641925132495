<template>
  <div class="accountWrap">
    <!-- 表单 -->
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="params" :inline="true">
        <!-- <el-form-item prop="EnterpriseFullName">
          <el-input placeholder="请输入内容" v-model="params.EnterpriseFullName" @keyup.enter.native="search()">
            <el-select v-model="params.currentEnterprise" slot="prepend">
              <el-option label="货主及服务商单位" value="1"></el-option>
              <el-option label="货主单位" value="2"></el-option>
              <el-option label="服务商单位" value="3"></el-option>
            </el-select>
          </el-input>
        </el-form-item> -->
        <el-form-item label="货主单位：" prop="DemanderName">
          <el-input class="fromInp" v-model="params.DemanderName" placeholder="输入货主单位" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="服务商单位：" prop="SupplierName">
          <el-input class="fromInp" v-model="params.SupplierName" placeholder="输入服务商单位" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="开票申请编号：" prop="InvoiceTaskID">
          <el-input class="fromInp" v-model="params.InvoiceTaskID" placeholder="输入开票申请编号" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="发票类型：" prop="InvoiceType">
          <el-select v-model="params.InvoiceType" clearable placeholder="请选择发票类型" @change="search()">
            <el-option v-for="item in options" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-form-item label="开票日期：" prop="date">
            <el-date-picker v-model="params.TaskDatetime" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
              value-format="yyyy-MM-dd" @change="searchChange()">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            <el-button type="primary" size="medium" @click="uploadClick()"
              :disabled="selectedTableItemList.length == '' ? true : false" icon="el-icon-download">批量下载</el-button>
            <el-button icon="el-icon-download" :disabled="!tableData.length" type="primary" size="medium"
              @click="exportExcel" :loading="loading">导出表格
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <!-- <div class="settleBox">
        <div style="display:flex;justify-content: space-between;">
          <div class="sum-item"><span class="sum-txt">历史总开票(张)：</span><span class="sum-num">{{
            invoices.InvoiceCount ? invoices.InvoiceCount : '/'
          }}</span></div>
          <div class="sum-item">
            <span class="sum-txt">历史运单数量(条)：</span><span class="sum-num">{{
              invoices.WaybillCount ? invoices.WaybillCount : '/'
            }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">历史总运费(元)：</span><span class="sum-num">{{
             invoiceTotals.TotalCarriageAmount
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">历史总税费(元)：</span><span class="sum-num">{{
           invoiceTotals.TotalTaxAmount
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">历史总支付金额(元)：</span><span class="sum-num">{{
            invoiceTotals.TotalPayTotalAmount
          }}</span></div>
        </div>
      </div> -->
      <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
        @selection-change="tableSelectionChange">
        <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="180" fixed="left"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ApplicantUserName" label="货主单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PrincipalCompanyName" label="服务商单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="开票完成时间" width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceNumber" label="总计运单数量" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率(%)" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="运费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TransportCosts }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="服务商收款总金额" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayCompleteDatetime" label="服务商收款时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ServiceCharge" label="平台技术服务费" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.ServiceCharge | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="服务商收益总金额" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.ServiceProviderProfit | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="remark" label="电子回单" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="uploadClick(scope.row)" icon="el-icon-download">
              下载回单
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-document">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize"  :page-sizes="[10,50,100,200]" layout="total,sizes, prev, pager, next, jumper"
          :total="pagination.total"  @size-change="sizeChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { tasksInfoList, taskSummary, downloadBankUrl, DownLoadIvoiceTasksInfoList } from "@/api/finance/account/index"
import { getDataDict } from "@/api/common/common.js";
export default {
  data() {
    return {
      // 发票类型数据
      options: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      // 选中的日期
      params: {
        TaskDatetime: ['', ''],
        InvoiceTaskID: "",
        // EnterpriseFullName: '',
        // currentEnterprise: '1',
        DemanderName: '',
        SupplierName: '',
        InvoiceType: ''
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 平台-所有分成汇总
      invoices: "",
      // 表格loading
      loading: true,
      // 需要下载的列表
      selectedTableItemList: [],
      invoiceTotals:{
        TotalCarriageAmount:'',
        TotalTaxAmount:'',
        TotalPayTotalAmount:''
      }
    };
  },
  created() {
    this.pagination.page = this.$route.query.pageIndex ? Number(this.$route.query.pageIndex) : 1
    this.params.TaskDatetime[0] = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    this.params.TaskDatetime[1] = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    this.params.InvoiceTaskID = this.$route.query.InvoiceTaskIDParams ? this.$route.query.InvoiceTaskIDParams : ''
    // this.params.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : '1'
    // this.params.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    this.params.DemanderName = this.$route.query.DemanderName ? this.$route.query.DemanderName : ''
    this.params.SupplierName = this.$route.query.SupplierName ? this.$route.query.SupplierName : ''
    this.params.InvoiceType = this.$route.query.InvoiceType || ''
    // 获取结算信息列表
    this.tasksInfoList();
    // 汇总开票相关信息
    // this.taskSummary();
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo)
    });
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    searchChange() {
      this.search()
    },

     //分页数量改变
     sizeChange(e) {
      this.pagination.pagesize = e;
      this.tasksInfoList();
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/finance/payment/index') {
        // 重置路由
        this.$router.push({
          path: "/finance/payment/index"
        });
      }
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e
    },
    // 下载电子回单
    uploadClick(item) {
      this.loading = true;
      if (item) {
        this.selectedTableItemList = [item]
      }
      let json = {
        InvoiceTaskIDs: [],
        WaybillIDs: []
      }
      this.selectedTableItemList.forEach((item) => {
        json.InvoiceTaskIDs.push(item.InvoiceTaskID)
      });
      downloadBankUrl(json).then(res => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      })
    },
    // 搜索
    search() {
      this.loading = true;
      this.pagination.page = 1;
      this.tasksInfoList();
    },
    // 清空搜索
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.params = {
        TaskDatetime: ['', ''],
        InvoiceTaskID: "",
        // EnterpriseFullName: '',
        // currentEnterprise: '1',
        DemanderName: '',
        SupplierName: '',
        InvoiceType: ''
      }
      this.tasksInfoList();
    },
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        // InvoiceNumber: '',
        // StartDatetime: '',
        // InvoiceType: '',
        // EnterpriseFullName: '',
        // RoleType: 2
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        // EnterpriseFullName: this.params.currentEnterprise == 1 ? this.params.EnterpriseFullName : '',
        // DemanderName: this.params.currentEnterprise == 2 ? this.params.EnterpriseFullName : '',
        // SupplierName: this.params.currentEnterprise == 3 ? this.params.EnterpriseFullName : '',
        DemanderName: this.params.DemanderName,
        SupplierName: this.params.SupplierName,
        RoleType: 1,
        InvoiceType: this.params.InvoiceType
      }
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data;
      });
    },
    // 获取结算信息列表
    tasksInfoList() {
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        // EnterpriseFullName: this.params.currentEnterprise == 1 ? this.params.EnterpriseFullName : '',
        // DemanderName: this.params.currentEnterprise == 2 ? this.params.EnterpriseFullName : '',
        // SupplierName: this.params.currentEnterprise == 3 ? this.params.EnterpriseFullName : '',
        DemanderName: this.params.DemanderName,
        SupplierName: this.params.SupplierName,
        RoleType: 1,
        InvoiceType: this.params.InvoiceType
      };
      tasksInfoList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
        this.invoiceTotals.TotalCarriageAmount=res.totals[0].TotalTransportCosts==0.00?'/':"￥"+res.totals[0].TotalTransportCosts ;
          this.invoiceTotals.TotalTaxAmount=res.totals[0].TotalExpensesOfTaxation==0.00?'/':"￥"+ res.totals[0].TotalExpensesOfTaxation;
          this.invoiceTotals.TotalPayTotalAmount=res.totals[0].TotalNeedPayMoney==0.00?'/':"￥"+res.totals[0].TotalNeedPayMoney ;
        // this.taskSummary()
      }).finally(() => {
        this.loading = false;
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e
      this.tasksInfoList()
    },
    //查看结算单详情
    goFacility(item) {
      this.$router.push({
        path: "/finance/payment/paymentInfo",
        query: {
          InvoiceTaskID: item.InvoiceTaskID,
          pageIndex: this.pagination.page,
          StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
          EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
          InvoiceTaskIDParams: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
          currentEnterprise: this.params.currentEnterprise,
          // EnterpriseFullName: this.params.EnterpriseFullName ? this.params.EnterpriseFullName : '',
          DemanderName: this.params.DemanderName ? this.params.DemanderName : '',
          SupplierName: this.params.SupplierName ? this.params.SupplierName : '',
          InvoiceType: this.params.InvoiceType
        },
      });
    },
    //导出excel
    exportExcel() {
      var invoiceTaskIds=[];
      if(this.selectedTableItemList){
        this.selectedTableItemList.forEach((item)=>
        {
          invoiceTaskIds.push(item.InvoiceTaskID);
        })
      }

      this.loading = true
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        DemanderName: this.params.DemanderName,
        SupplierName: this.params.SupplierName,
        RoleType: 1,
        InvoiceType: this.params.InvoiceType,
        DwonType: 2, // 下载类型：1-结算，2-资金
        InvoiceTaskIds:invoiceTaskIds,
      };

      DownLoadIvoiceTasksInfoList({ Json: JSON.stringify(data) }).then((res) => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.accountWrap {
  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    padding: 0 32%;
    text-align: center;

    .accountInfo {
      width: 30vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.settleBox {
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #f0f0f0;
  color: #666;
}

.sum-item {
  color: rgb(255, 139, 23);
  margin-right: 15px;
  white-space: nowrap;

  .sum-txt {
    color: #333;
    font-weight: bold;
    text-align: right;
    width: 120px;
    display: inline-block;
  }

  .sum-num {
    display: inline-block;
    width: 200px;
  }
}
</style>